<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="1000px"
      @click:outside="close"
      @keydown.esc="close"
      @load.stop="getData"
    >
      <v-card>
        <v-progress-linear
          :active="isDialogVisible"
          :indeterminate="isDialogVisible"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">{{ item.id !== null ? "Modifier la Campagne" : "Nouvelle campagne" }}</span>
          <v-spacer />
          <v-btn x-small @click="getData(null)"
          >Charger les utilisateurs
          </v-btn>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="item.title['fr']"
                    type="text"
                    label="Titre - FR"
                    :rules="[rules.title]"
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="item.title['en']"
                    type="text"
                    label="Titre - EN"
                    :rules="[rules.title]"
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    v-model="item.body['fr']"
                    type="text"
                    label="Contenu - FR"
                    :rules="[rules.body]"
                    hide-details
                    dense
                    rows="2"
                  ></v-textarea>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    v-model="item.body['en']"
                    type="text"
                    label="Contenu - EN"
                    :rules="[rules.body]"
                    hide-details
                    dense
                    rows="2"
                  ></v-textarea>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-content-between"
                >
                  <v-file-input
                    v-model="item.image['fr']"
                    show-size
                    counter
                    label="Image - FR"
                    @change="uploadFr"
                  ></v-file-input>
                  <v-img
                    height="50"
                    width="50"
                    :src="`${item.image['fr']}`"
                  ></v-img>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-content-between"
                >
                  <v-file-input
                    v-model="item.image['en']"
                    show-size
                    counter
                    label="Image - EN"
                    @change="uploadEn"
                  ></v-file-input>
                  <v-img
                    height="50"
                    width="50"
                    :src="`${item.image['en']}`"
                  ></v-img>
                </v-col>
                <!--                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.to"
                    label="To"
                    type="text"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.url"
                    label="URL"
                    type="text"
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>-->
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="item.target"
                    :items="targets"
                    item-text="text"
                    item-value="value"
                    label="Cible"
                    dense
                    clearable
                    hide-details
                    :rules="[rules.target]"
                  ></v-select>
                </v-col>
                <v-col
                  v-if="item.target === 'specific'"
                  cols="12"
                >
                  <!--
                  <v-textarea
                    v-model="item.usersids"
                    label="Utilisateurs"
                    dense
                    clearable
                    hide-details
                    rows="2"
                  ></v-textarea>-->
                  <v-autocomplete
                    v-model="item.users_ids"
                    :items="users"
                    item-value="id"
                    item-text="name"
                    :loading="searching"
                    multiple
                    chips
                    clearable
                    deletable-chips
                    @update:search-input="search"

                  >
                    <template #item="{item}">
                      <v-list-item-avatar>
                        <v-img :src="item.profile_picture_path" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{item.name}}</v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-if="item.email">{{item.email}}</span>
                          <span v-if="item.phone_number" class="mx-2">, {{item.phone_number}}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                              </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-menu
                    ref="menuref"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.send_date"
                        label="Date d'envoi"
                        v-bind="attrs"
                        :rules="[rules.send_date]"
                        dense
                        hide-details
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="item.send_date"
                      :min="new Date().toISOString()"
                      color="primary"
                      @input="menu = false"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-menu
                    ref="menurefTime"
                    v-model="menuTime"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="item.send_time"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.send_time"
                        label="Heure d'envoi"
                        v-bind="attrs"
                        :rules="[rules.send_time]"
                        dense
                        hide-details
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menuTime"
                      ref="pickerTicker"
                      v-model="item.send_time"
                      color="primary"
                      format="24hr"
                      @click:minute="$refs.menurefTime.save(item.send_time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-switch
                    v-model="item.status"
                    label="Statut"
                    :true-value="1"
                    :false-value="0"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-switch
                    v-model="item.sent"
                    label="Envoyé"
                    :true-value="1"
                    :false-value="0"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              {{ item.id !== null ? "Modifier" : "Enregistrer" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, ref, watch,
  onMounted,
} from '@vue/composition-api'
import { mdiCalendar, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import { formatDate } from '@core/date/dateFunctions'
import DatePickerCustom from '@core/components/datepicker/DatePickerCustom'
import axiosIns from '@axios'
import store from '@/store'

export default {
  name: 'CampaignForm',
  components: { DatePickerCustom },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },

  emits: ['clickout', 'submitted'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    object: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)

    const menu = ref(false)
    const users = ref([])
    const timeout = ref(null)
    const searching = ref(false)
    const menuTime = ref(false)
    const color = ref(null)
    const picker = ref(null)
    const menuref = ref(null)
    const pickerTime = ref(null)
    const menurefTime = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const targets = ref([
      { text: 'Tous', value: 'all' },
      { text: 'Chauffeurs', value: 'drivers' },
      { text: 'Passagers', value: 'passengers' },
      { text: 'Spécifique', value: 'specific' },
    ])
    const rules = {
      title: true,
      body: true,
      code: true,
      value: true,
      target: true,
      quantity_per_user: true,
      is_percent: true,
      send_date: true,
      send_time: true,
    }

    watch(menu, val => {
      // eslint-disable-next-line no-return-assign,no-unused-expressions

      // val && setTimeout(() => (picker.value.activePicker = 'DATE'), 500)
    })


    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    //item.value.users_ids = props.object.usersids ? props.object.usersids.split(',') : []
    const instance = getCurrentInstance()
    const getData = (val) => {
      if(val) {
        searching.value = true
      }

      axiosIns.post('admin/users/members', { keyword: val, usersids: item.value.usersids, users_ids: props.object && props.object.usersids?props.object.usersids.split(','):[], paginate: (props.object && props.object.usersids?props.object.usersids.split(','):[]).length || 100 }).then(response => {
        console.log('response', response.data.result.data)
        searching.value = false
        if (response.data.result && response.data.result.data) {
          const { data } = response.data.result
          for (let i = 0; i < data.length; i++) {
            var da = data[i]
            const index = users.value.findIndex(e => e.id == da.id)
            if (index < 0) {
              users.value.push(da)
            }
          }
        }
      }).then(() => {
        searching.value = false
      })
    }
    const close = () => {
      message.value = ''
      rules.label = true
      rules.code = true
      rules.send_date = true
      rules.value = true
      rules.init_quantity = true
      rules.quantity_per_user = true
      rules.send_date = true
      isAlertVisible.value = false
      instance.emit('clickout')
    }

    const save = d => {
      menuref.value.save(d)
    }

    const submitForm = () => {
      isAlertVisible.value = false

      isDialogVisible.value = true
      if (item.value.id !== null) {
        store.dispatch('campaign/updateCampaign', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          close()
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.label = error.response.data.errors.label !== undefined ? error.response.data.errors.label[0] : true
            rules.code = error.response.data.errors.code !== undefined ? error.response.data.errors.code[0] : true
            rules.value = error.response.data.errors.value !== undefined ? error.response.data.errors.value[0] : true
            rules.init_quantity = error.response.data.errors.init_quantity !== undefined ? error.response.data.errors.init_quantity[0] : true
            rules.quantity_per_user = error.response.data.errors.quantity_per_user !== undefined ? error.response.data.errors.quantity_per_user[0] : true
            rules.is_percent = error.response.data.errors.is_percent !== undefined ? error.response.data.errors.is_percent[0] : true
            rules.send_date = error.response.data.errors.send_date !== undefined ? error.response.data.errors.send_date[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      } else {
        store.dispatch('campaign/addCampaign', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          close()
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.label = error.response.data.errors.label !== undefined ? error.response.data.errors.label[0] : true
            rules.code = error.response.data.errors.code !== undefined ? error.response.data.errors.code[0] : true
            rules.value = error.response.data.errors.value !== undefined ? error.response.data.errors.value[0] : true
            rules.init_quantity = error.response.data.errors.init_quantity !== undefined ? error.response.data.errors.init_quantity[0] : true
            rules.quantity_per_user = error.response.data.errors.quantity_per_user !== undefined ? error.response.data.errors.quantity_per_user[0] : true
            rules.is_percent = error.response.data.errors.is_percent !== undefined ? error.response.data.errors.is_percent[0] : true
            rules.send_date = error.response.data.errors.send_date !== undefined ? error.response.data.errors.send_date[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      }
    }

    const uploadFr = event => {
      const formData = new FormData()
      formData.append('image', event)
      store.dispatch('upload/uploadFile', { model: 'campaign', field: formData }).then(() => {
        item.value.image.fr = store.state.upload.file.chemin
      })
    }
    const uploadEn = event => {
      const formData = new FormData()
      formData.append('image', event)
      store.dispatch('upload/uploadFile', { model: 'campaign', field: formData }).then(() => {
        item.value.image.en = store.state.upload.file.chemin
      })
    }

    const search = e => {
      clearTimeout(timeout.value)
      if (e && e.trim().length) {
        timeout.value = setTimeout(() => {

          getData(e)
        }, 2000)
      }
    }

    watch([props.dialog], val => {
       getData(null)
    })
    setTimeout(()=>{

      getData(null)
      //
    },2000)
    return {
      uploadEn,
      uploadFr,
      close,
      search,
      getData,
      searching,
      targets,
      users,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      menu,
      menuTime,
      picker,
      pickerTime,
      menuref,
      menurefTime,
      item,
      color,
      save,

      // icons
      icons: {
        mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
      },
    }
  },

}
</script>

<style scoped>

</style>
